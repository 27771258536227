<template>
    <el-dialog :visible.sync="open" :show-close="false" :close-on-click-modal="false"
               :modal="false">
        <img class="bag_sty" src="@/assets/img/Group5192.png" alt="">
        <div class="close_sty" @click="closeDialog"></div>
        <div class="title">
            住宿
        </div>
        <div class="empty_box"></div>
        <div class="fl_r ju_c" style="position: relative;width: 100%;">
            <div class="pos_r tab_box" style="cursor: pointer" v-for="(item,index) in tabItem" :key="index"
                 @click="chooseTab(index,item)">
                <img class="img_sty" :src="selectTab==index?item.selectPath:item.path" alt="">
                <div class="tab_title">{{item.title}}</div>
            </div>
        </div>
        <div class="content_box fl_r ju_c">
            <div class="left_box fl_r fl_w">
                <div class="left_item fl_c" v-for="(item,index) in itemList" :key="index">
                    <div class="fl_r ju_b">
                        <div class="fl_c">
                            <div class="fl_r ju_b">
                                <div class="name">{{item.name}}</div>
                                <!-- <div class="price">{{item.distance}}</div> -->
                            </div>
                            <div class="detail">
                                距离:{{item.distance}}米</br>
                                {{item.address}}
                            </div>
                        </div>
                        <img class="image" :src="item.titleImg" alt="">
                    </div>

                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: "index",
        components: {},
        props: {
            open: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                itemList:[],
                severType:'',
                tabItem: [{
                    title: '推荐',
                    path: require('../../assets/img/Group5137.png'),
                    selectPath: require('../../assets/img/Group5136.png'),
                }, {
                    title: '景点',
                    path: require('../../assets/img/Group5137.png'),
                    selectPath: require('../../assets/img/Group5136.png'),
                }, {
                    title: '酒店',
                    path: require('../../assets/img/Group5137.png'),
                    selectPath: require('../../assets/img/Group5136.png'),
                }, {
                    title: '美食',
                    path: require('../../assets/img/Group5137.png'),
                    selectPath: require('../../assets/img/Group5136.png'),
                }],
                selectTab: 0
            }
        },
        mounted() {

        },
        created(){
            this.getList();
        },

        methods: {
            getList(){
                this.$api.getPageList({
						dataType: 'periphery',
						type: this.severType,
						pageNum: this.page,
						pageSize: this.pageSize
					}).then(res=>{
						let datalist=res.data.list;
						datalist.forEach(e=>{
							if(e.photos){
								e.photos=JSON.parse(e.photos);
								e['titleImg']=e.photos[0].url;
							}
							
						})
						
						this.itemList.push(...datalist)
						
						this.totalPage = res.totalPage
						
					}).finally(()=>{
						this.isLoading = false
						reslove(true)
					})
            },
            chooseTab(index,item) {
                this.selectTab = index
                this.severType=item.title
                if(index==0){
                    this.severType="";
                }
                if(this.severType=='美食'){
                    this.severType="餐饮"
                }
                this.itemList=[];
                this.getList();
            },
            closeDialog() {
                this.$emit('update:open', false)
            },
        },
    }
</script>

<style scoped lang="scss">

   ::v-deep .el-dialog {
        background-color: transparent;
        width: 1070.55px;
        height: 767.29px;
    }


   ::v-deep .el-dialog__wrapper {
        border: none;
    }

   ::v-deep .el-dialog__header, .el-dialog__footer {
        background-color: transparent;
    }

   ::v-deep .el-dialog__header {
        padding: 0;
    }

   ::v-deep .el-dialog__body {
        padding: 0;
    }

    .close_sty {
        width: 70.55px;
        height: 70.55px;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }

    .bag_sty {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .title {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: 73px;
        width: auto;
        height: 22px;
        font-size: 20px;
        font-family: CTBiaoSongSJ, CTBiaoSongSJ;
        font-weight: 100;
        color: #FFFFFF;
        line-height: 23px;
        letter-spacing: 2px;
        text-shadow: 0px 1px 3px rgba(14, 88, 147, 0.75);
    }

    .empty_box {
        width: 100%;
        height: 116px;
    }

    ::-webkit-scrollbar-thumb {
        width: 3px;
        height: 200px;
        background: #81C8FF;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
    }

    ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    .content_box {
        width: 930px;
        height: 480px;
        position: relative;
        margin-top: 10px;
        margin-left: 80px;

        .left_box {
            width: 100%;
            height: 100%;
            border-radius: 10px 0px 0px 10px;
            overflow-y: auto;

            .left_item {
                width: 450px;
                height: 168px;
                background: rgba(89, 188, 255, 0.35);
                border-radius: 10px 10px 10px 10px;
                opacity: 1;
                margin: 8px 6px;
                padding: 15px;

                .name {
                    width: auto;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 800;
                    color: #FFFFFF;
                    line-height: 16px;
                }

                .price {
                    width: auto;
                    height: 17px;
                    font-size: 13px;
                    font-family: DINPro, DINPro;
                    font-weight: bold;
                    color: #FFA827;
                    line-height: 15px;
                    padding-right: 12px;
                }

                .detail {
                    width: 191px;
                    height: 72px;
                    font-size: 13px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 15px;
                    margin-top: 12px;
                }

                .image {
                    width: 226.97px;
                    height: 138px;
                    border-radius: 6.49px;
                }
            }

            .left_item:nth-child(odd) {

                background: rgba(89, 188, 255, 0.35);
            }
        }


    }

    .tab_box {
        padding: 0;

    }

    .img_sty {

    }

    .tab_title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: 25px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        color: #FFFFFF;
        line-height: 21px;
        letter-spacing: 2px;
    }
</style>
