<template>
    <div class="contain fl_c al_c">
        <div class="title">
            通知公告
        </div>
        <div class="item white_space_one" v-for="(item,index) in 12" :key="index">
            公告事件：消息消息消息消息消息消息消息消息消息消息消...
        </div>
    </div>
</template>

<script>
    export default {
        name: "notificationAnnouncement",
        components: {},
        data() {
            return {}
        },
        mounted() {

        },
        methods: {},
    }
</script>

<style scoped lang="scss">
    .contain {
        width: 100%;
        height: 309px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url(../../assets/img/Group5071.png);
    }

    .title {
        width: 490px;
        height: 42px;
        font-size: 20px;
        font-family: CTBiaoSongSJ, CTBiaoSongSJ;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 42px;
        letter-spacing: 2px;
        text-shadow: 0px 1px 3px rgba(14, 88, 147, 0.75);
        padding-left: 54px;
        margin-top: 15px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url(../../assets/img/title.png);
    }
    .item{
        width: 354px;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        letter-spacing: 1px;
        margin-top: 21px;
    }
    .item:nth-child(odd){
        color: #7AC6FF;
    }
</style>
