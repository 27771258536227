import axios from 'axios';

const service = axios.create({
    // 基础的请求地址
    baseURL: process.env.NODE_ENV == 'development' ? 'https://dxpg.jdoffices.cn/apis/do?appid=supply&aid=' : 'https://dxpg.jdoffices.cn/apis/do?appid=supply&aid=',
    // 设置超时时间 5s
    timeout: 5000,
    // 让请求在浏览器中允许跨域携带cookie,若后台配置了跨域，还报，则改成false
    withCredentials: false,
    // 在向服务器发送前，修改请求数据
    transformRequest: [(data, headers) => {
        headers["Content-Type"] = 'application/json;charset=utf-8'
        //这个可以放获取到的token放在请求头
        return JSON.stringify(data)
    }]
});

// 请求拦截器，例如请求的时候在头部加上请求的token
service.interceptors.request.use(config => {
    return config; //  有且必须有一个config对象被返回
});

// 响应拦截器,例如判断服务器返回的状态，400，500啥的，其实超时可以写到这里面来，
service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data.code == '0000') {
                return Promise.resolve(response.data);
            }
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况,这些自定义（需要与后台商量返回）
    error => {
        console.log(error)
        // 用户信息失效，重新登录，或者其他问题可以再次设置全局的回应
        return Promise.reject(error)
    }
);

export default service;
