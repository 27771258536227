<template>
  <div class="contain fl_c al_c">
    <div class="title">实时监控</div>
    <div class="fl_r mar_top1">
      <video autoplay="autoplay" loop="loop" width="100%" class="mlh">
        <source src="@/assets/webm/CAMERAV.webm" type="video/mp4" />
      </video>
      <div class="fl_c">
        <!-- <img src="@/assets/img/Group5084.png" alt=""> -->
        <video autoplay="autoplay" loop="loop" width="100%" class="mh">
          <source src="@/assets/webm/CAMERAV.webm" type="video/mp4" />
        </video>
        <!-- <video-player :options="playerOptions" ref="video"></video-player> -->
        <!-- <img class="mar_top" src="@/assets/img/Group5084.png" alt=""> -->
        <video autoplay="autoplay" loop="loop" width="100%" class="mh">
          <source src="@/assets/webm/CAMERAV.webm" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import { videoPlayer } from "vue-video-player";

export default {
  name: "scenicWeather",
  components: { videoPlayer },
  data() {
    return {
      videoSrc: this.Src,
      playerOptions: {
        live: true,
        autoplay: true, // 如果true，浏览器准备好时开始播放
        muted: false, // 默认情况下将会消除任何音频
        loop: false, // 是否视频一结束就重新开始
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          currentTimeDisplay: false, // 当前时间
          volumeControl: false, // 声音控制键
          playToggle: false, // 暂停和播放键
          progressControl: false, // 进度条
          fullscreenToggle: true, // 全屏按钮
        },
        techOrder: ["flash"], // 兼容顺序
       
        sources: [
          {
           // type: "rtmp/flv",
             type:"video/mp4",
            src: "../../assets/webm/CAMERAV.webm", // 视频地址-改变它的值播放的视频会改变
          },
        ],
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.contain {
  width: 100%;
  height: 344px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/Rectangle3057.png);
}

.title {
  width: 490px;
  height: 42px;
  font-size: 20px;
  font-family: CTBiaoSongSJ, CTBiaoSongSJ;
  font-weight: normal;
  color: #ffffff;
  line-height: 42px;
  letter-spacing: 2px;
  text-shadow: 0px 1px 3px rgba(14, 88, 147, 0.75);
  padding-left: 54px;
  margin-top: 15px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/title.png);
}

.mar_top1 {
  margin-top: 20px;
}

.mlh {
  height: 240px;
}

.mh {
  height: 120px;
}
</style>
