<template>
  <el-dialog :visible.sync="open" :show-close="false" :close-on-click-modal="false" :modal="false"  width="1400px">
    <img class="bag_sty" src="@/assets/img/Group5192.png" alt="" />
    <div class="close_sty" @click="closeDialog"></div>
    <div class="title">VR</div>
    <div class="empty_box"></div>

    <div class="content_box fl_r ju_c">
      <iframe id="frame" :src="vrUrl" style="width: 85%; height:100%;border: 0px; margin: 0;"></iframe>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "index",
  components: {},
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    vrUrl:{
        type:String,
        default:''
    }
  },
  data() {
    return {
      itemList: [],
    };
  },
  mounted() {
    
  },
  methods: {
   
    chooseTab(index) {
      this.selectTab = index;
    },
    closeDialog() {
      this.$emit("update:open", false);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  background-color: transparent;
  width: 1200px;
  height: 900px;
}

::v-deep .el-dialog__wrapper {
  border: none;
}

::v-deep .el-dialog__header,
.el-dialog__footer {
  background-color: transparent;
}

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  padding: 0;
}

.close_sty {
  width: 70.55px;
  height: 70.55px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.bag_sty {
  width: 100%;
  height: 100%;
  position: absolute;
}

.title {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 73px;
  width: auto;
  height: 22px;
  font-size: 20px;
  font-family: CTBiaoSongSJ, CTBiaoSongSJ;
  font-weight: 100;
  color: #ffffff;
  line-height: 23px;
  letter-spacing: 2px;
  text-shadow: 0px 1px 3px rgba(14, 88, 147, 0.75);
}

.empty_box {
  width: 100%;
  height: 116px;
}

::-webkit-scrollbar-thumb {
  width: 3px;
  height: 200px;
  background: #81c8ff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.content_box {
  width: 100%;
  height: 550px;
  position: relative;
  margin-top: 10px;

  .left_box {
    width: 335px;
    height: 100%;
    margin-left: 15px;
    border-radius: 10px 0px 0px 10px;
    overflow-y: auto;
    .left_item {
      width: 330px;
      height: 168px;
      background: rgba(6, 47, 81, 0.76);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;

      .but_icon {
        width: 13px;
        height: 18.2px;
        margin: 10px 10px 0 20px;
      }

      .bus_name {
        width: auto;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 16px;
        margin: 10px 0 6px 0;
      }
      .bus_number {
        width: auto;
        height: 17px;
        background: #2094ff;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        font-size: 13px;
        font-weight: 500;
        color: #ffffff;
        line-height: 17px;
        padding: 0 6px;
        text-align: center;
        margin: 8px 0 0 10px;
      }
      .bus_box {
        width: 286px;
        height: auto;

        .bus_title {
          width: auto;
          height: 18px;
          font-size: 13px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 15px;
          margin-right: 15px;
          margin-bottom: 10px;
        }
      }
    }

    .left_item:nth-child(odd) {
      background: rgba(89, 188, 255, 0.35);
    }
  }

  .right_box {
    width: 570px;
    height: 100%;
    margin-left: 15px;
    border-radius: 0px 10px 10px 0px;
  }
}
</style>
