<template>
  <div class="contain fl_c al_c">
    <div class="title">绳金塔景点</div>
    <div class="fl_c"  @click="handleShowSsp">
      <el-carousel height="320px" class="" @change="handleChange" indicator-position="none">
        <el-carousel-item v-for="(item, index) in secCatetory" :key="index">
          <img :src="item.logoImg" alt="" class="banner_img"/>
        </el-carousel-item>
      </el-carousel>
      <!-- <img src="@/assets/img/Rectangle3110.png" alt=""> -->
      <div class="content" >
        <!-- v-html="spot.content" -->
        <h2>{{ spot.name }}</h2>
      </div>
    </div>
    <the-scenic-spot ref="refSsp" :open.sync="showSsp" :sspList="secCatetory"/>
  </div>
</template>

<script>
import theScenicSpot from '@/components/theScenicSpot';
export default {
  name: "scenicWeather",
  components: {theScenicSpot},
  data() {
    return {
      secCatetory: [],
      spot: {},
      showSsp:false
    };
  },
  mounted() {},
  created() {
    this.pageList();
  },
  methods: {
    pageList() {
      this.$api
        .getChildrensTitle()
        .then((res) => {
          this.secCatetory = res.data.list;
          if (this.secCatetory && this.secCatetory.length > 0) this.spot = this.secCatetory[0];
        })
        .catch((err) => {});
    },
    handleChange(idx) {
     
      if (this.secCatetory[idx].content == undefined) {
        this.$api.getScenicSpot({ id: this.secCatetory[idx].id }).then((res) => {
          this.secCatetory[idx]["content"] = res.data.description;
          this.spot = this.secCatetory[idx];
        });
      } else {
        this.spot = this.secCatetory[idx];
      }
    },
    handleShowSsp(){
      this.showSsp=true;
      this.$refs.refSsp.setSelect(this.spot);
     // this.$emit('OnEvent',this.spot);
    }
  },
};
</script>

<style scoped lang="scss">
.contain {
  width: 100%;
  height: 493px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/Group5078.png);
}

.title {
  width: 490px;
  height: 42px;
  font-size: 20px;
  font-family: CTBiaoSongSJ, CTBiaoSongSJ;
  font-weight: normal;
  color: #ffffff;
  line-height: 42px;
  letter-spacing: 2px;
  text-shadow: 0px 1px 3px rgba(14, 88, 147, 0.75);
  padding-left: 54px;
  margin-top: 15px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/title.png);
}
img {
  margin: 20px 0;
  width:100%;
  height:auto;
}
.content {
  margin-top:20px;
  width: 448px;
  // height: 160px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  line-height: 16px;
  letter-spacing: 1px;

 
}
</style>
