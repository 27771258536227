<template>
  <div class="contain fl_c al_c">
    <div class="title">实时车流量</div>
    <div class="fl_r fl_w al_c ju_c" style="height: 100%; width: 100%">
      <div>        
        <!-- <h2>车辆流动</h2> -->
        <car-line :chartData="carData"></car-line>
      </div>
      
    </div>
  </div>
</template>

<script>
import carLine from './carLine'
export default {
  name: "scenicWeather",
  props: { weatherObj: { type: Object, default: {} } },
  components: {carLine},
  data() {
    return {
      carData:{}
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.contain {
  width: 100%;
  height: 275px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/Rectangle3056.png);
}

.title {
  width: 490px;
  height: 42px;
  font-size: 20px;
  font-family: CTBiaoSongSJ, CTBiaoSongSJ;
  font-weight: normal;
  color: #ffffff;
  line-height: 42px;
  letter-spacing: 2px;
  text-shadow: 0px 1px 3px rgba(14, 88, 147, 0.75);
  padding-left: 54px;
  margin-top: 15px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/title.png);
}
.item_box {
  width: 98.6px;
  height: 72px;
  margin: 0 51px;
  img {
    margin-right: 25px;
  }
  .top_title {
    width: auto;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 16px;
    letter-spacing: 1px;
    margin-bottom: 5px;
    white-space: nowrap;
  }
  .bottom_title {
    width: auto;
    height: 25px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 21px;
    letter-spacing: 2px;
    white-space: nowrap;
  }
}
</style>
