<template>
  <div class="fl_c large_box pos_r">
    <img class="centerImg" src="@/assets/img/Maskgroup.png" alt="" />
    <img class="bagSty" src="@/assets/img/bag.png" alt="" />
    <div class="fl_c al_c large_title pos_r" style="z-index: 9999">
      <img src="@/assets/img/topImg.png" alt="" />
      <h1>绳金塔数据可视化</h1>
    </div>
    <div class="topItem fl_r ju_b">
      <div class="fl_r">
        <div class="fl_r">
          <!-- <img src="@/assets/img/location.png" alt="" />
          <span>南昌市 西湖区</span> -->
        </div>
        <div class="fl_r">
          <img src="@/assets/img/date.png" alt="" />
          <span style="width:155px">{{ newDate }}</span>
        </div>
        <div class="fl_r">
          <img src="@/assets/img/time.png" alt="" />
          <span>{{ newTime }}</span>
        </div>
      </div>
      <div class="fl_r">
        <div class="fl_r">
          <img src="@/assets/img/weather.png" alt="" />
          <span>{{ weatherObj?.real?.weather?.info }} {{ weatherObj?.real?.weather?.temperature }}
          {{weatherObj?.air?.text}}
          </span>
          
        </div>
        <div class="fl_r">
        <!-- <img src="@/assets/img/time.png" alt="" /> -->
          <span>{{weatherObj?.real?.wind.direct}}{{weatherObj?.real?.wind.speed}}级</span> 
        </div>
      </div>
    </div>

    <div class="center_box fl_r fl_w">
      <img class="icon" v-for="(item, index) in tabIcon" :key="index" :src="selectTab == index ? item.selectPath : item.path" alt="" @click="chooseTab(index)" />
    </div>
    <div class="fl_r fl ju_c">
      <div class="large_left fl_c">
        <div class="width_box">
          <scenic-weather :weatherObj="weatherObj" />
        </div>
        <div class="width_box">
          <flow-monitor :weatherObj="weatherObj" />
        </div>        
        <div class="width_box">
          <notification-announcement />
        </div>
      </div>
      <div class="center_box1">
        <ul class="zpsl-box">
                   <li>
                       <span>今日游客</span>
                       <span>26777</span>
                   </li>
                   <li>
                       <span>今日车辆</span>
                       <span>523</span>
                   </li>
         </ul>
         <div class="vrbtn" @click="showVr=true">
          <div class="floating1">
                    <div id="roate1">
                       
                    </div>
                    <p class="pm">VR</p>
                </div>
        </div>
      </div>
      <div class="large_left fl_c">
        <div class="width_box">
          <qifu-district @OnEvent="onShowSsp"/>
        </div>
        <div class="width_box">
          <monitor />
        </div>
      </div>
    </div>
    <the-bus :open.sync="showTheBus" />
    <the-stay :open.sync="showTheStay" />
    <the-restaurant :open.sync="showTheRestaurant" />
    <the-product :open.sync="showTheProduct" />
    <the-periphery :open.sync="showThePeriphery" />
    <the-vr :open.sync="showVr" :vrUrl="homeData.vrUrl"/>
  </div>
</template>

<script>
import axios from "axios";
import screenfull from "screenfull";
import { indexHome, statisticsMessage } from "@/utils/api/index";
import scenicWeather from "../components/scenicWeather/scenicWeather";
import notificationAnnouncement from "../components/notificationAnnouncement/notificationAnnouncement";
import flowMonitor from '@/components/flowMonitor'
import qifuDistrict from "../components/qifuDistrict/qifuDistrict";
import moreAttractions from "../components/moreAttractions/moreAttractions";
import monitor from "@/components/monitor/monitor";
import theBus from "../components/theBus/index";
import theStay from "../components/theStay/index";
import theRestaurant from "../components/theRestaurant/index";
import theProduct from "../components/theProduct/index";
import thePeriphery from "../components/thePeriphery/index";
import theVr from "../components/theVr/index";
import { formatDate } from "@/utils/jdeps";

export default {
  name: "smartAgriculture",
  components: {
    scenicWeather,
    flowMonitor,
    notificationAnnouncement,
    qifuDistrict,
    monitor,
    theBus,
    theStay,
    theRestaurant,
    theProduct,
    thePeriphery,
    theVr
  },
  computed: {
    optionLeft() {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data() {
    return {
      homeData:{},
        timeInterval: undefined,
      isScreenfull: true,
      propertyList: [],
      orderList: [],
      proportionList: [],
      unsalableData: [],
      unsalableDataName: [],
      scaleList: [],
      scaleStock: [],
      newDate: "",
      newTime: "",
      city: "龙岩",
      weatherObj: {},
      newsList: [],
      cropData: [],
      townData: [],

      formalCity: "",
      count_list: {},

      tabIcon: [
        {
          path: require("../assets/img/Group5092.png"),
          selectPath: require("../assets/img/Group5093.png"),
        },
        {
          path: require("../assets/img/Group5091.png"),
          selectPath: require("../assets/img/Group5094.png"),
        },
        {
          path: require("../assets/img/Group5090.png"),
          selectPath: require("../assets/img/Group5095.png"),
        },
        {
          path: require("../assets/img/Group5089.png"),
          selectPath: require("../assets/img/Group5096.png"),
        },
        {
          path: require("../assets/img/Group5088.png"),
          selectPath: require("../assets/img/Group5097.png"),
        },
        {
          path: require("../assets/img/Group5087.png"),
          selectPath: require("../assets/img/Group5086.png"),
        },
      ],
      selectTab: 0,
      showTheBus: false,
      showTheStay: false,
      showTheRestaurant: false,
      showTheProduct: false,
      showThePeriphery: false,
      showSsp:false,
      showVr:false
    };
  },
  watch: {
    selectTab(val) {
      if (val == 1) {
        this.showTheBus = true;
      } else if (val == 2) {
        this.showTheStay = true;
      } else if (val == 3) {
        this.showTheRestaurant = true;
      } else if (val == 4) {
        this.showTheProduct = true;
      } else if (val == 5) {
        this.showThePeriphery = true;
      }
    },
    showTheBus(val) {
      if (val == false) this.selectTab = 0;
    },
    showTheStay(val) {
      if (val == false) this.selectTab = 0;
    },
    showTheRestaurant(val) {
      if (val == false) this.selectTab = 0;
    },
    showTheProduct(val) {
      if (val == false) this.selectTab = 0;
    },
    showThePeriphery(val) {
      if (val == false) this.selectTab = 0;
    },
  },
  created() {
    this.initHome()
    //  this.statisticsMessage()
    // this.homeStatistics()
   this.interval();
    this.weather();
    this.newDate = this.$moment().format("YYYY-MM-DD");
    this.newTime = this.$moment().format("HH:mm:ss");
  },

  methods: {
    initHome(){
      this.$api.getHome().then(res=>{
        this.homeData=res.data;
      })
    },
    interval() {
      if (this.timeInterval) {
        clearInterval(this.timeInterval);
      }
        this.timeInterval = setInterval(()=>{
          // 在这里写需要执行的代码
          
          const date = formatDate(new Date());
          this.newDate = date.day;
          this.newTime = date.time;
         
          //整点更新最新天气
          if(this.newTime.endsWith('00:00')){
            this.weather();
          }
         
        }, 1000);
      
    },
    chooseTab(index) {
      this.selectTab = index;
    },
    resetCity() {
      this.formalCity = "";
      this.indexHome();
    },
    getFormalCity(e) {
      this.formalCity = e;
      this.unsalableDataName = [];
      this.unsalableData = [];
      this.indexHome();
    },
    async weather() {
      // let res = await axios.get("https://yiketianqi.com/api?unescape=1&version=v6&appid=32368591&appsecret=hqci6rDS&city=南昌")
      //this.weatherObj = res.data
      // console.log('w:',this.weatherObj)
      
      // axios.get("http://www.nmc.cn/rest/weather?stationid=58606").then(res=>{
      //   this.weatherObj = res.data;
      //   console.log('wwww',this.weatherObj);
      // });
     
    
      this.$api.getWeather().then((res) => {
        this.weatherObj = res.data;
      });
      console.log('w:',this.weatherObj)
    },
    async indexHome() {
      let res = await indexHome({
        type: "02",
        area_code: "龙岩市",
        town_code: this.formalCity,
        statistical_type: "01",
      });
      this.propertyList = res.data.property_list;
      this.orderList = res.data.order_list;
      this.proportionList = res.data.proportion_list;
      this.scaleList = res.data.scale_list;
      this.scaleStock = res.data.scale_stock;
      this.count_list = res.data.count_list;

      res.data.unsalableData[0].map((item) => {
        item.date = this.$moment(item.date).format("YYYY-MM-DD");
      });

      res.data.unsalableData.map((item) => {
        this.unsalableDataName.push(item[0].produceName);
      });
      this.unsalableData = res.data.unsalableData;
      this.newsList.push({ title: res.data.topString });
    },

    async statisticsMessage() {
      let res = await statisticsMessage();
      this.cropData = res.data.cropData.reverse();
      this.townData = res.data.townData;
    },

    // async homeStatistics() {
    //     let res = await homeStatistics({
    //         type: '02',
    //         area_code: '龙岩市',
    //         statistical_type: '01'
    //     })
    //
    //     res.data.unsalableData[0].map(item => {
    //         item.date = this.$moment(item.date).format('YYYY-MM-DD')
    //     })
    //     res.data.unsalableData.map(item => {
    //         this.unsalableDataName.push(item[0].produceName)
    //     })
    //     this.unsalableData = res.data.unsalableData
    //     this.newsList.push({title: res.data.topString})
    // },
    requestFullScreen() {
      this.isScreenfull = screenfull.isFullscreen;
      if (screenfull.isEnabled) {
        screenfull.toggle();
      }
    },
    onShowSsp(item){
      console.log(item);
      this.showSsp=true;
    }
  },
};
</script>

<style scoped lang="scss">
.centerImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.large_box {
  width: 100%;
  height: 100%;
}

.large_left {
  width: 522px;
  height: 100%;
  margin: 0 20px;
}

.center_box {
  width: 781px;
  height: 95.9px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 32px;

  .icon {
    width: 115.04px;
    height: 95.9px;
    margin-left: 18px;
    cursor: pointer;
  }

  .icon:first-child {
    margin-left: 0;
  }
}

.center_box1 {
  width: 781px;
  height: 1px;
}

.width_box {
  width: 522px;
  margin-bottom: 15px;
}

.width_box:nth-of-type(3n) {
  margin-bottom: 0px;
}

.bagSty {
  width: 100%;
  height: 100%;
  z-index: -99999;
  position: fixed;
}

.large_title {
  height: 120px;
  color: #fff;
  font-size: 20px;
  letter-spacing: 2px;

  h1 {
    position: absolute;
    top: 10px;

    width: 354px;
    height: 43px;
    font-size: 40px;
    font-family: CTBiaoSongSJ, CTBiaoSongSJ;
    font-weight: normal;
    color: #ffffff;
    line-height: 47px;
    letter-spacing: 4px;
  }
}

.topItem {
  position: fixed;
  top: 50px;
  width: 100%;
  padding: 0 71px;
  z-index: 99999;

  span {
    width: 150px;
    height: 30px;
    font-size: 18px;
    font-family: CTBiaoSongSJ, CTBiaoSongSJ;
    font-weight: 100;
    color: #ffffff;
    line-height: 30px;
    letter-spacing: 2px;
    text-shadow: 0px 1px 3px rgba(14, 88, 147, 0.75);
    margin-left: 16px;
  }
}
.vrbtn{
    width: 100px;
    height: 100px;
    float: left;
    margin: 0 110px;
    position: relative;
    
}
.vrbtn:hover{
  cursor: pointer;
}

/*zpsl-box*/
.zpsl-box{
    width: 100%;
    height: 155px;
   
}
.zpsl-box li{
    width: 155px;
    height: 155px;
    float: left;
    margin: 0 110px;
    position: relative;
    
}

.zpsl-box li:nth-of-type(2):before{
    width: 155px;height: 155px;
    content: "";
    position: absolute;
    top:0;left:0;
    background:url("../assets/img/bqzlbg002.png") no-repeat;
    background-size: 100% 100%;
    animation: animation-rotate1 4s linear infinite;
}
.zpsl-box li:nth-of-type(1):before{
    width: 155px;height: 155px;
    content: "";
    position: absolute;
    top:0;left:0;
    background:url("../assets/img/bqzlbg001.png") no-repeat;
    background-size: 100% 100%;
    animation: animation-rotate 4s linear infinite;
}
@keyframes animation-rotate {
    0% {
        transform: rotatez(360deg)
    }
    100% {
        transform: rotatez(0deg)
    }
}
@keyframes animation-rotate1 {
    0% {
        transform: rotatez(0deg)
    }
    100% {
        transform: rotatez(360deg)
    }
}
.zpsl-box li span{
    display: block;
    width: 100%;
    text-align: center;
    color:#ffffff;
    font-size: 14px;
}
.zpsl-box li span:nth-of-type(1){
    font-size: 18px;
    font-weight: bold;
    margin-top: 50px;
}
.zpsl-box li span:nth-of-type(2){
    margin-top: 10px;
}
.zpsl-box li:nth-of-type(1) span:nth-of-type(1){
    color:#9DF9FC
}
.zpsl-box li:nth-of-type(2) span:nth-of-type(1){
    color:#D3FCA0
}

.floating1 {
  width: 70%;
  height: 75%;
  background: url(../assets/img/vr.png) no-repeat bottom center;
  background-size: 90%;
  animation: float 8s linear infinite;
}
.floating1 .pm {
  text-align: center;
  color: #fff;
  font-size: 36px;
}

@keyframes float {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(60%);
  }
  100% {
    transform: translateY(0%);
  }
}
</style>
