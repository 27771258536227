<template>
  <div class="contain fl_c al_c">
    <div class="title">景区天气</div>
    <div class="fl_r fl_w al_c ju_c" style="height: 100%; width: 90%">
      <!-- <span class="warn">级别：{{weatherObj?.real?.warn?.signallevel}}</span> -->
      <span class="warn cont">{{weatherObj?.real?.warn?.issuecontent}}</span>      
    </div>
    <div v-if="false" class="fl_r fl_w al_c ju_c" style="height: 100%; width: 100%">
      <div class="item_box fl_r">
        <img src="@/assets/img/group5027.png" alt="" />
        <div class="fl_c al_c ju_c">
          <div class="top_title">天气</div>
          <div class="bottom_title">{{ weatherObj?.real?.weather?.info }}</div>
        </div>
      </div>
      <div class="item_box fl_r">
        <img src="@/assets/img/group13.png" alt="" />
        <div class="fl_c al_c ju_c">
          <div class="top_title">温度</div>
          <div class="bottom_title">{{ weatherObj?.real?.weather?.temperature }}</div>
        </div>
      </div>
      <div class="item_box fl_r">
        <img src="@/assets/img/group9.png" alt="" />
        <div class="fl_c al_c ju_c">
          <div class="top_title">湿度</div>
          <div class="bottom_title">{{ weatherObj?.air?.text }}</div>
        </div>
      </div>
      <div class="item_box fl_r">
        <img src="@/assets/img/Group11.png" alt="" />
        <div class="fl_c al_c ju_c">
          <div class="top_title">PM2.5</div>
          <div class="bottom_title">38</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "scenicWeather",
  props: { weatherObj: { type: Object, default: {} } },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.contain {
  width: 100%;
  height: 275px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/Rectangle3056.png);
}

.title {
  width: 490px;
  height: 42px;
  font-size: 20px;
  font-family: CTBiaoSongSJ, CTBiaoSongSJ;
  font-weight: normal;
  color: #ffffff;
  line-height: 42px;
  letter-spacing: 2px;
  text-shadow: 0px 1px 3px rgba(14, 88, 147, 0.75);
  padding-left: 54px;
  margin-top: 15px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/title.png);
}
.item_box {
  width: 98.6px;
  height: 72px;
  margin: 0 51px;
  img {
    margin-right: 25px;
  }
  .top_title {
    width: auto;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 16px;
    letter-spacing: 1px;
    margin-bottom: 5px;
    white-space: nowrap;
  }
  .bottom_title {
    width: auto;
    height: 25px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 21px;
    letter-spacing: 2px;
    white-space: nowrap;
  }
}
.warn{
  font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color:yellow;
    
}
.cont{
  height:150px;
}
</style>
