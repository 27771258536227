import request from '../request.js'


//农作物统计信息
export function statisticsMessage(data) {
    return request({
        url: 'api_crop_statistics_message',
        method: 'post',
        data
    })
}

//农作物统计信息
export function indexHome(data) {
    return request({
        url: 'api_dp_home',
        method: 'post',
        data
    })
}


//滞销系统展示
export function homeStatistics(data) {
    return request({
        url: 'api_dp_home_statistics',
        method: 'post',
        data
    })
}


//天地图的接口
export function homepageMap(data) {
    return request({
        url: 'api_map_homepage',
        method: 'post',
        data
    })
}


//农场地图的接口
export function getMapDetail(data) {
    return request({
        url: 'api_shop_vr_map',
        method: 'post',
        data
    })
}
