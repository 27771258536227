<template>
    <el-dialog :visible.sync="open" :show-close="false" :close-on-click-modal="false"
               :modal="false">
        <img class="bag_sty" src="@/assets/img/Group5192.png" alt="">
        <div class="close_sty" @click="closeDialog"></div>
        <div class="title">
            住宿
        </div>
        <div class="empty_box"></div>

        <div class="content_box fl_r ju_c">
            <div class="left_box">
                <div class="left_item fl_c" v-for="(item,index) in itemList" :key="index">
                    <div class="fl_r">
                        <img class="but_icon" src="@/assets/img/Group5140.png" alt="">
                        <div>
                            <div class="fl_r ju_b">
                                <div class="fl_r">
                                    <div class="bus_name">{{item.name}}</div>
                                    <div class="bus_number">{{item.phone}}</div>
                                </div>
                                <div class="bus_name pad_right">距景点{{item.distance}}米</div>
                            </div>
                            <div class="bus_box">
                                <div class="bus_title">{{item.address}}</div>
                                <img src="@/assets/img/Rectangle3126.png" alt="">
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="right_box">

            </div>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: "index",
        components: {},
        props: {
            open: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                itemList:[]
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            getList(){
                this.$api.getPageList({
						dataType: 'periphery',
						type: '住宿',
						pageNum: this.page,
						pageSize: this.pageSize
					}).then(res=>{
						let datalist=res.data.list;
						datalist.forEach(e=>{
							if(e.photos){
								e.photos=JSON.parse(e.photos);
								e['titleImg']=e.photos[0].url;
							}
							
						})
						
						this.itemList.push(...datalist)
						
						this.totalPage = res.totalPage
						
					}).finally(()=>{
						this.isLoading = false
						reslove(true)
					})
            },
            chooseTab(index) {
                this.selectTab = index
            },
            closeDialog() {
                this.$emit('update:open', false)
            },
        },
    }
</script>

<style scoped lang="scss">

   ::v-deep .el-dialog {
        background-color: transparent;
        width: 1070.55px;
        height: 767.29px;
    }


   ::v-deep .el-dialog__wrapper {
        border: none;
    }

   ::v-deep .el-dialog__header, .el-dialog__footer {
        background-color: transparent;
    }

   ::v-deep .el-dialog__header {
        padding: 0;
    }

   ::v-deep .el-dialog__body {
        padding: 0;
    }

    .close_sty {
        width: 70.55px;
        height: 70.55px;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }

    .bag_sty {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .title {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: 73px;
        width: auto;
        height: 22px;
        font-size: 20px;
        font-family: CTBiaoSongSJ, CTBiaoSongSJ;
        font-weight: 100;
        color: #FFFFFF;
        line-height: 23px;
        letter-spacing: 2px;
        text-shadow: 0px 1px 3px rgba(14, 88, 147, 0.75);
    }

    .empty_box {
        width: 100%;
        height: 116px;
    }

    ::-webkit-scrollbar-thumb {
        width: 3px;
        height: 200px;
        background: #81C8FF;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
    }

    ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }
    .content_box {
        width: 100%;
        height: 550px;
        position: relative;
        margin-top: 10px;

        .left_box {
            width: 335px;
            height: 100%;
            margin-left: 15px;
            border-radius: 10px 0px 0px 10px;
            overflow-y: auto;
            .left_item {
                width: 330px;
                height: 168px;
                background: rgba(6, 47, 81, 0.76);
                border-radius: 0px 0px 0px 0px;
                opacity: 1;

                .but_icon {
                    width: 13px;
                    height: 18.2px;
                    margin: 10px 10px 0 20px;
                }

                .bus_name {
                    width: auto;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 16px;
                    margin: 10px 0 6px 0;
                }
                .bus_number {
                    width: auto;
                    height: 17px;
                    background: #2094FF;
                    border-radius: 2px 2px 2px 2px;
                    opacity: 1;
                    font-size: 13px;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 17px;
                    padding: 0 6px;
                    text-align: center;
                    margin: 8px 0 0 10px;
                }
                .bus_box {
                    width: 286px;
                    height: auto;

                    .bus_title {
                        width: auto;
                        height: 18px;
                        font-size: 13px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 15px;
                        margin-right: 15px;
                        margin-bottom: 10px;
                    }


                }

            }

            .left_item:nth-child(odd) {

                background: rgba(89, 188, 255, 0.35);
            }
        }

        .right_box {
            width: 570px;
            height: 100%;
            margin-left: 15px;
            border-radius: 0px 10px 10px 0px;
        }
    }
</style>
